<template>
  <div id="app">
    <input type="text" v-model="pwd" placeholder="请输入密码" style="height: 40px;font-size: 18px;" v-if="wspwd != pwd">

    <!-- 预测金额表 -->
    <!-- <div class="a" v-if="wspwd === pwd"
      style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;border:1px solid #000;margin-bottom: 10px;">

      <div style="background-color: #000;color: white;">预测</div>
      <div>1</div>
      <div>2</div>
      <div>3</div>
      <div>4</div>
      <div>5</div>
      <div>6</div>
      <div style="font-size: 12px;">总落子</div>
      <div style="background-color: #000;color: white;">金额</div>
      <div :style="{ color: a1 * 1 > 0 ? 'blue' : 'red' }">{{ a1 }}</div>
      <div :style="{ color: a2 * 1 > 0 ? 'blue' : 'red' }">{{ a2 }}</div>
      <div :style="{ color: a3 * 1 > 0 ? 'blue' : 'red' }">{{ a3 }}</div>
      <div :style="{ color: a4 * 1 > 0 ? 'blue' : 'red' }">{{ a4 }}</div>
      <div :style="{ color: a5 * 1 > 0 ? 'blue' : 'red' }">{{ a5 }}</div>
      <div :style="{ color: a6 * 1 > 0 ? 'blue' : 'red' }">{{ a6 }}</div>
      <div :style="{ color: a7 * 1 > 0 ? 'blue' : 'red' }">{{ a7 }}</div>
    </div> -->
    <div class="yc-table" v-if="wspwd === pwd">
      <div style="background-color: #000;color: white;">预测</div>
      <div style="background-color: #000;color: white;">金额</div>
      <div>1</div>
      <div :style="{ color: a1 * 1 > 0 ? 'blue' : 'red' }">{{ a1 }}</div>
      <div>2</div>
      <div :style="{ color: a2 * 1 > 0 ? 'blue' : 'red' }">{{ a2 }}</div>
      <div>3</div>
      <div :style="{ color: a3 * 1 > 0 ? 'blue' : 'red' }">{{ a3 }}</div>
      <div>4</div>
      <div :style="{ color: a4 * 1 > 0 ? 'blue' : 'red' }">{{ a4 }}</div>
      <div>5</div>
      <div :style="{ color: a5 * 1 > 0 ? 'blue' : 'red' }">{{ a5 }}</div>
      <div>6</div>
      <div :style="{ color: a6 * 1 > 0 ? 'blue' : 'red' }">{{ a6 }}</div>
      <div>总落子</div>
      <div :style="{ color: a7 * 1 > 0 ? 'blue' : 'red' }">{{ a7 }}</div>
    </div>
    <div class="b" style="border-top: 1px solid #000;" v-if="wspwd === pwd">
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;">

        <div>群号</div>
        <div>应付</div>
        <div>落子</div>
        <div>小费</div>
        <div>输赢</div>
      </div>
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;" v-for="item, index in c" :key="index"
        class="b2-content">
        <div>{{ item.desno }}号</div>
        <div :style="{ color: item.yf * 1 > 0 ? 'blue' : 'red' }">{{ item.yf }}</div>
        <div>{{ item.lz }}</div>
        <div>{{ item.xf }}</div>
        <div :style="{ color: item.sy * 1 > 0 ? 'blue' : 'red' }">{{ item.sy }}</div>
      </div>
    </div>

    <div class="b" style="border-top: 1px solid #000;margin-top:20px" v-if="wspwd === pwd && Object.keys(hddzFilterData).length>0">
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;">

        <div>群号</div>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
        <div>5</div>
        <div>6</div>
      </div>
      <div style="display: grid;grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;" v-for="item, index in hddzFilterData" :key="index"
        class="b2-content">
        <div>{{ item['wjx.desno'] }}号</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][0] }}</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][1] }}</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][2] }}</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][3] }}</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][4] }}</div>
        <div :style="{ color: item['yuce'][0] * 1 > 0 ? 'blue' : 'red' }">{{ item['yuce'][5] }}</div>
 
      </div>
    </div>
    <!-- {{hddzFilterData}} -->
    <!-- <div @click="refresh" style="border:1px solid red;padding: 15px;">刷新</div> -->
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => {
    return {
      a1: 0,
      a2: 0,
      a3: 0,
      a4: 0,
      a5: 0,
      a6: 0,
      a7: 0,
      str: '{"desno":"6","win":0.00,"luozi":10.762,"yuce":"-2.14@-0.63@-0.63@0.63@0.63@0.63"},{"desno":"7","win":0.00,"luozi":10.762,"yuce":"-2.14@-0.63@-0.63@0.63@0.63@0.63"},{"desno":"8","win":0.00,"luozi":10.762,"yuce":"-2.14@-0.63@-0.63@0.63@0.63@0.63"}',
      b: {},
      c: [],
      ws: {},
      inter: {},
      wspwd: undefined,
      pwd: undefined,
      hasPwd: true,
      hddzFilterData:{},
    }
  },
  methods: {
    refresh: function (ele) {
      // let obj = JSON.parse('[' + this.str + ']')

      this.b[ele.desno + '-'] = ele
      let k = Object.keys(this.b)
      this.c = []
      k.forEach(ele => {
        this.c.push(this.b[ele])
      })
      this.c.sort((p1, p2) => {
        return p1.desno * 1 - p2.desno * 1
        // return p2.fori * 1 - p1.fori * 1
      })
    }
  },
  mounted() {

    // var comet_url = "ws://121.41.55.141:3010"
    // var comet_url = "ws://feiniao2.erpgj.com:3010/ws"
    var comet_url = "ws://121.41.55.141:2010/"

    this.ws = new WebSocket(comet_url)
    this.ws.onopen = function () {


      // 心跳包
      this.inter = window.setInterval(() => {
        let r = this.ws.send("line")
        // console.log(r)
        if (r === undefined || r === null || r === "") {
          // window.clearInterval(inter)
        }
      }, 1000)
    }.bind(this)

    this.ws.onmessage = function (res) {
      try {

        if (res && res.data) {


          let obj = JSON.parse(res.data)
          if(obj['wjx.desno']){
            obj['yuce']=obj['yuce'].split('@')
            this.hddzFilterData[obj['wjx.desno']]=obj;
            return ;
          } 
          if (obj['a1']) this.a1 = obj['a1']
          if (obj['a2']) this.a2 = obj['a2']
          if (obj['a3']) this.a3 = obj['a3']
          if (obj['a4']) this.a4 = obj['a4']
          if (obj['a5']) this.a5 = obj['a5']
          if (obj['a6']) this.a6 = obj['a6']
          if (obj['a7']) this.a7 = obj['a7']
          if (obj['fori']) this.refresh(obj)

          if (obj['pwd']) {
            this.wspwd = obj['pwd']
          }
        }
      
      } catch (error) {
        // console.log("onmessage,", error)
      }
      // if(res.data[])
    }.bind(this)
    this.ws.onclose = function (res) {
      console.log("close,", res)
      // window.refresh()
      // window.clearInterval(this.inter)
    }.bind(this)
    this.ws.onerror = function (err) {
      // window.clearInterval(this.inter)
      console.log("err,", err)
    }.bind(this)

  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;

}

.a>div {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  display: flex;
  font-size: 14px;
  align-items: center;
  align-content: center;
  justify-content: center;

}

.b>div {
  border-bottom: 1px solid #000;
  border-left: 1px solid #000;
  display: flex;
  font-size: 14px;
  align-items: center;
  align-content: center;
  justify-content: center;

}

.b2-content {
  font-weight: bold;
}

.b>div>div {
  border-right: 1px solid #000;
}

.yc-table {
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 50%;
  border-left: 1px solid #000;
  margin-bottom: 15px;


}

.yc-table>div {
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid #000;
  border-right: 1px solid #000;
  font-size: 18px;
}
</style>
